/* By default, display the entire <blockquote> content */
blockquote {
  display: none;
}

blockquote.expanded {
  display: block;
}

button.togglebutton {
  background: none;
  border: none;
  color: #000; /* Set the color to your desired text color */
  font-weight: 800; /* Make the text bold */
  font-size: 16px;
  cursor: pointer;
  outline: none; /* Remove the button outline */
}
button.togglebutton:hover {
  text-decoration: underline; /* Optionally add underline on hover */
}
